import React , { useState, useEffect, useCallback } from 'react';
import { Link, graphql } from 'gatsby';

import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs, Autoplay, EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/effect-fade';

import { Script } from "gatsby";
import { 
  LayoutLp,
  SEO,
  AssetImage,
  CLpModalNewyear,
} from '../../../components/_index';
import { useMobile } from '../../../utils/use-series';
import '../../../assets/_sass/page/sp/newyear2025.scss';
import { useInView } from "react-intersection-observer";

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const isSp = useMobile();
  // const [scrollFlag, setScrollFlag] = useState<Boolean>(false);
  // useEffect(() => {
  //   window.addEventListener('scroll', scrollEvent);
  //   return () => window.removeEventListener('scroll', scrollEvent);
  // }, []);
  // const scrollEvent = useCallback(() => {
  //   if (
  //     window.scrollY > document.querySelectorAll('.l_headerLpNewyear')[0].clientHeight
  //   ) {
  //     document.querySelectorAll('.lp_newYearFooter')[0].classList.remove('disnon');
  //   }
  // }, [scrollFlag]);

  // 追従アンカー制御
  const [showMenu, setShowMenu] = useState(false)
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    if (scrollTop > 50) { // 50px以上スクロールしたら表示
      setShowMenu(true);
    } else {
      setShowMenu(false)
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Swiper
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [thumbsSwiper1, setThumbsSwiper1] = useState(null);
  const [thumbsSwiper2, setThumbsSwiper2] = useState(null);
  const [thumbsSwiper3, setThumbsSwiper3] = useState(null);
  const [thumbsSwiper4, setThumbsSwiper4] = useState(null);

  // modalSwiper
  const [thumbsSwiper5, setThumbsSwiper5] = useState(null);

  // modal
  // event
  const [modalFlagEvent, setModalFlagEvent] = useState(false);
  // osechi
  const [modalFlag, setModalFlag] = useState(false);
  const [modalFlag02, setModalFlag02] = useState(false);
  const [modalFlag03, setModalFlag03] = useState(false);

  const openModalEvent = (e: any) => {
    e.stopPropagation();
    setModalFlagEvent(!modalFlagEvent);
  };
  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };
  const openModal02 = (e: any) => {
    e.stopPropagation();
    setModalFlag02(!modalFlag02);
  };
  const openModal03 = (e: any) => {
    e.stopPropagation();
    setModalFlag03(!modalFlag03);
  };

  // アコーディオン
  const defaultFlag = false;
  const [openFlag, setOpenFlag] = useState(defaultFlag);
  const toggleClick = () => {
    setOpenFlag(!openFlag)
  }

  const useCustomInView = () => {
    return useInView({
      threshold: 0.3,
      triggerOnce: true,
    });
  };
  const [refPc1, inViewPc1] = useCustomInView();
  const [refPc2, inViewPc2] = useCustomInView();
  const [refPc3, inViewPc3] = useCustomInView();
  const [refPc4, inViewPc4] = useCustomInView();
  const [refPc5, inViewPc5] = useCustomInView();
  const [refPc6, inViewPc6] = useCustomInView();
  const [refPc7, inViewPc7] = useCustomInView();
  const [refPc8, inViewPc8] = useCustomInView();

  const [refSp1, inViewSp1] = useCustomInView();
  const [refSp2, inViewSp2] = useCustomInView();
  const [refSp3, inViewSp3] = useCustomInView();
  const [refSp4, inViewSp4] = useCustomInView();
  const [refSp5, inViewSp5] = useCustomInView();
  const [refSp6, inViewSp6] = useCustomInView();
  const [refSp7, inViewSp7] = useCustomInView();
  const [refSp8, inViewSp8] = useCustomInView();
  const [refSp9, inViewSp9] = useCustomInView();

  return (
    <LayoutLp isNewyear={true}>
      <Script dangerouslySetInnerHTML={{
        __html: `
(function() {
    const tabList = document.querySelectorAll('.lp_tabItem');
    const tabListSp = document.querySelectorAll('.lp_tabItemSp');
    const tabContent = document.querySelectorAll('.lp_tabContentsItem');
    const tabContentSp = document.querySelectorAll('.lp_tabContentsItemSp');

    function tabSwitch() {
      document.querySelectorAll('.active')[0].classList.remove('active');
      this.classList.add('active');
      document.querySelectorAll('.show')[0].classList.remove('show');
      const aryTabs = Array.prototype.slice.call(tabList);
      const index = aryTabs.indexOf(this);
      tabContent[index].classList.add('show');
    };
    for (let i = 0; i < tabList.length; i++) {
      tabList[i].addEventListener('click', tabSwitch);
    }

    function tabSwitchSp() {
      document.querySelectorAll('.activeSp')[0].classList.remove('activeSp');
      this.classList.add('activeSp');
      document.querySelectorAll('.showSp')[0].classList.remove('showSp');
      const aryTabs = Array.prototype.slice.call(tabListSp);
      const index = aryTabs.indexOf(this);
      tabContentSp[index].classList.add('showSp');
    };
    for (let i = 0; i < tabListSp.length; i++) {
      tabListSp[i].addEventListener('click', tabSwitchSp);
    }
})(document)

        `}} />
      <SEO
        title={frontmatter?.title}
        description={frontmatter?.description}
        ogimage={`${process.env.BASE_URL}/assets/images/sp/newyear2025/icon/ogimage.png`}
      />
      <div className="lp_newyear2025">
        <a href="#" className={`arrow ${showMenu ? 'showArrow' : ''}`}></a>
        <section className="lp_kv">
          <Swiper
            loop={true}
            effect={'fade'}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, EffectFade]}
            speed={3000}
            fadeEffect={{ crossFade: true }}
          >
            <SwiperSlide className="kv_slide">
              <div className="item">
                <figure className="imgWrapper u_pc">
                  <AssetImage src="/assets/images/sp/newyear2025/mv.png" alt="" loading="lazy" />
                </figure>
                <figure className="imgWrapper u_sp">
                  <AssetImage src="/assets/images/sp/newyear2025/mv__sp.png" alt="" loading="lazy" />
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide className="kv_slide">
              <div className="item">
                <figure className="imgWrapper u_pc">
                  <AssetImage src="/assets/images/sp/newyear2025/mv02.png" alt="" loading="lazy" />
                </figure>
                <figure className="imgWrapper u_sp">
                  <AssetImage src="/assets/images/sp/newyear2025/mv02__sp.png" alt="" loading="lazy" />
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide className="kv_slide">
              <div className="item">
                <figure className="imgWrapper u_pc">
                  <AssetImage src="/assets/images/sp/newyear2025/mv03.png" alt="" loading="lazy" />
                </figure>
                <figure className="imgWrapper u_sp">
                  <AssetImage src="/assets/images/sp/newyear2025/mv03__sp.png" alt="" loading="lazy" />
                </figure>
              </div>
            </SwiperSlide>
          </Swiper>
        </section>
        <section className="lp_sect01">
          <div ref={refPc1} className={`plum_1-1 u_pc ${inViewPc1 ? "animation" : ""}`}></div>
          <div ref={refPc2} className={`plum_1-2 u_pc ${inViewPc2 ? "animation" : ""}`}></div>
          <div ref={refSp1} className={`plum_1-1_sp u_sp ${inViewSp1 ? "animation" : ""}`}></div>
          <div ref={refSp2} className={`plum_1-2_sp u_sp ${inViewSp2 ? "animation" : ""}`}></div>
          <div ref={refSp7} className={`plum_1-3_sp u_sp ${inViewSp7 ? "animation" : ""}`}></div>
          <div className="lp_menu">
            <div className="lp_menuTextBox">
              <div className="lp_menuText">
                お正月ならではのイベントや料理とともに、ホテルで充実した年末年始を満喫していただけます。<br className="u_pc" />ご家族や大切な方と新春の心華やぐ時間をお楽しみください。
              </div>
            </div>
          </div>
          <div className="lp_menuList">
            <a href="#restaurant">
              <div className="lp_menuItem">
                <img src="/assets/images/sp/newyear2025/icon/restaurantbox.png" alt=""/>
                <div className="lp_menuItemText">レストラン</div>
              </div>
            </a>
            <a href="#plan">
              <div className="lp_menuItem">
                <img src="/assets/images/sp/newyear2025/icon/planbox.png" alt=""/>
                <div className="lp_menuItemText">ご宿泊プラン</div>
              </div>
            </a>
            <a href="#event">
              <div className="lp_menuItem">
                <img src="/assets/images/sp/newyear2025/icon/eventbox.png" alt=""/>
                <div className="lp_menuItemText">イベント</div>
              </div>
            </a>
            <a href="#osechi">
              <div className="lp_menuItem">
                <img src="/assets/images/sp/newyear2025/icon/osechibox.png" alt=""/>
                <div className="lp_menuItemText">おせち料理</div>
              </div>
            </a>
          </div>
          <div className="lp_sect01Bg">
            <div className="lp_sectTitleWrap">
              <img src="/assets/images/sp/newyear2025/icon/restaurant_icon.png" alt=""/>
              <div className="lp_sectTitle" id="restaurant">
                レストラン
              </div>
            </div>
            <ul className="lp_tabList">
              <li className="lp_tabItem active">
                ランチ
              </li>
              <li className="lp_tabItem">
                ディナー
              </li>
            </ul>
            <div className="lp_tabContents">
              <div className="lp_tabContentsItem show">
                <Swiper
                  onSwiper={setThumbsSwiper}
                  loop={false}
                  spaceBetween={10}
                  slidesPerView={3}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper swiperFirst"
                  breakpoints={{
                    640: {
                      slidesPerView: 7,
                      spaceBetween: 10
                    }
                  }}
                >
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/restaurant/restaurant_lunch_01.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/restaurant/restaurant_lunch_02.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/restaurant/restaurant_lunch_03.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/restaurant/restaurant_lunch_04.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/restaurant/restaurant_lunch_05.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/restaurant/restaurant_lunch_06.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/restaurant/restaurant_lunch_07.png" />
                  </SwiperSlide>
                </Swiper>
                <div className="border u_pc"></div>
                <Swiper
                  loop={false}
                  spaceBetween={0}
                  navigation={true}
                  thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  <SwiperSlide>
                    <div className="lp_swiperContents3">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/restaurant/restaurant_lunch_01.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_swiperTextContentsTitleStay">
                            <div className="lp_swiperTextContentsTitleStay--sub">
                              20F 鉄板焼 すみだ
                            </div>
                            <div className="lp_swiperTextContentsTitleStay--bold">
                              新春鉄板焼ステーキ食べ放題
                            </div>
                          </div>
                          <div className="px-1">
                            <div className="lp_swiperTextContentsListText">
                              人気の鉄板焼ステーキ食べ放題を、年末年始も開催いたします。和牛カルビや国産牛サーロインなど豪華なメニューを取り揃えました。ご家族やグループで、新年の優雅なお集まりにぜひご利用ください。
                            </div>
                          </div>
                          <ul className="mt-30">
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2024年12月31日（火）～2025年1月5日（日）<br />
                                1部 11:30～13:00／2部 13:30～15:00
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　12,000円
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※ お子様、シニア料金あり
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/restaurants/plan/uye6d7qy5/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents3">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/restaurant/restaurant_lunch_02.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_swiperTextContentsTitleStay">
                            <div className="lp_swiperTextContentsTitleStay--sub">
                              20F 鉄板焼 すみだ
                            </div>
                            <div className="lp_swiperTextContentsTitleStay--bold">
                              新春ランチコース
                            </div>
                          </div>
                          <div className="px-1">
                            <div className="lp_swiperTextContentsListText">
                              九州産和牛を主役にした贅沢なランチコースをご用意いたしました。牛肉の焼き寿司や煮鮑など、新年を彩る逸品をぜひご堪能ください。
                            </div>
                          </div>
                          <ul className="mt-30">
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2024年12月31日（火）～2025年1月5日（日）<br />
                                1部 11:30～13:00／2部 13:30～15:00
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　16,000円
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/restaurants/plan/c9le950hyyo/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents3">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/restaurant/restaurant_lunch_03.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_swiperTextContentsTitleStay">
                            <div className="lp_swiperTextContentsTitleStay--sub">
                              5F 日本料理 源氏香
                            </div>
                            <div className="lp_swiperTextContentsTitleStay--bold">
                              初釜
                            </div>
                          </div>
                          <div className="px-1">
                            <div className="lp_swiperTextContentsListText">
                              新年を祝うお茶会「初釜」。日本庭園を眺め、穏やかで晴れやかな心地でごゆっくりお楽しみください。
                            </div>
                          </div>
                          <ul className="mt-30">
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2025年1月1日（元日）、1月2日（木）<br />
                                11:00～16:00
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　3,300円
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/restaurants/plan/f3tfkdqkr5/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents3">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/restaurant/restaurant_lunch_04.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_swiperTextContentsTitleStay">
                            <div className="lp_swiperTextContentsTitleStay--sub">
                              5F 日本料理 源氏香
                            </div>
                            <div className="lp_swiperTextContentsTitleStay--bold">
                              新春ランチ会席
                            </div>
                          </div>
                          <div className="px-1">
                            <div className="lp_swiperTextContentsListText">
                              新たな年の幕開けにふさわしいお祝い仕立ての会席をご用意いたしました。美しい日本庭園を眺めながらお楽しみください。
                            </div>
                          </div>
                          <ul className="mt-30">
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2025年1月1日（元日）～1月5日（日）<br />
                                1部 11:30～13:30／2部 14:00～16:00
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　13,000円
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※ 1月4日、1月5日の営業時間は詳細よりご確認ください。
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/restaurants/plan/8s7hjo8cu/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents3">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/restaurant/restaurant_lunch_05.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_swiperTextContentsTitleStay">
                            <div className="lp_swiperTextContentsTitleStay--sub">
                              B1F 中国料理 桂花苑
                            </div>
                            <div className="lp_swiperTextContentsTitleStay--bold">
                              新春ランチコース
                            </div>
                          </div>
                          <div className="px-1">
                            <div className="lp_swiperTextContentsListText">
                              干し貝柱やフカヒレ、和牛やタラバガニなどの食材を、新春ならではの華やかな盛付けでお楽しみいただけます。
                            </div>
                          </div>
                          <ul className="mt-30">
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2024年12月31日（火）～2025年1月5日（日）<br />
                                1部 11:30～13:30／2部 14:00～16:00
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　9,000円～
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※ 1月4日、1月5日の営業時間は詳細よりご確認ください。<br />
                                ※ 写真は13,000円のコースです。
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/restaurants/plan/ipqc1kkbd49n/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents3">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/restaurant/restaurant_lunch_06.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_swiperTextContentsTitleStay">
                            <div className="lp_swiperTextContentsTitleStay--sub">
                              1F シェフズダイニング シンフォニー
                            </div>
                            <div className="lp_swiperTextContentsTitleStay--bold">
                              新春ファミリーランチブッフェ
                            </div>
                          </div>
                          <div className="px-1">
                            <div className="lp_swiperTextContentsListText">
                              年始の集いに、幅広い世代の皆様にお楽しみいただけるランチブッフェをご用意いたしました。ラグジュアリーな空間で笑顔咲く新春のひとときをお過ごしください。
                            </div>
                          </div>
                          <ul className="mt-30">
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2025年1月1日（元日）～1月5日（日）<br />
                                1部 11:30～13:00／2部 13:30～15:00
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　9,000円
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※ お子様、シニア料金あり
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/restaurants/plan/65xl7rgnm0d/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents3">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/restaurant/restaurant_lunch_07.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_swiperTextContentsTitleStay">
                            <div className="lp_swiperTextContentsTitleStay--sub">
                              1F ロビーラウンジ フォンテーヌ
                            </div>
                            <div className="lp_swiperTextContentsTitleStay--bold">
                              新春アフタヌーンティー
                            </div>
                          </div>
                          <div className="px-1">
                            <div className="lp_swiperTextContentsListText">
                              匠の技が光るチョコレート細工に、和栗と柚子を使ったテリーヌ、洋風に仕立てたおこしなどの和スイーツがラインナップ。お正月ならではのアフタヌーンティーをぜひご堪能ください。
                            </div>
                          </div>
                          <ul className="mt-30">
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2024年12月26日（木）～2025年1月19日（日）<br />
                                平日 11:00～15:00　土・日・祝日 11:00～17:00
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　8,000円
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※ 写真は2名様分です。
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/restaurants/plan/c8mwiq0t4/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              
              <div className="lp_tabContentsItem">
                <Swiper
                  onSwiper={setThumbsSwiper1}
                  loop={false}
                  spaceBetween={10}
                  slidesPerView={3}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper swiper1"
                  breakpoints={{
                    640: {
                      slidesPerView: 7,
                      spaceBetween: 10
                    }
                  }}
                >
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/restaurant/restaurant_dinner_01.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/restaurant/restaurant_dinner_02.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/restaurant/restaurant_dinner_03.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/restaurant/restaurant_dinner_04.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/restaurant/restaurant_dinner_05.png" />
                  </SwiperSlide>
                </Swiper>
                <div className="border u_pc"></div>
                <Swiper
                  loop={false}
                  spaceBetween={0}
                  navigation={true}
                  thumbs={{ swiper: thumbsSwiper1 }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  <SwiperSlide>
                    <div className="lp_swiperContents3">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/restaurant/restaurant_dinner_01.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_swiperTextContentsTitleStay">
                            <div className="lp_swiperTextContentsTitleStay--sub">
                              20F 鉄板焼 すみだ
                            </div>
                            <div className="lp_swiperTextContentsTitleStay--bold">
                              新春ディナーコース
                            </div>
                          </div>
                          <div className="px-1">
                            <div className="lp_swiperTextContentsListText">
                              九州産和牛や神戸ビーフまたは仙台牛がメインのコースを2種類ご用意。海老や数の子、タラバガニやオマール海老など新たな年の幕開けにふさわしい華やかなお食事とともに、贅沢なひとときをお過ごしください。
                            </div>
                          </div>
                          <ul className="mt-30">
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2024年12月31日（火）～2025年1月5日（日）<br />
                                1部 17:00～19:00／2部 19:30～21:30
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様 23,000円〜
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※ 1月4日、1月5日の営業時間は詳細よりご確認ください。<br />
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/restaurants/plan/l3mj0b37m/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents3">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/restaurant/restaurant_dinner_02.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_swiperTextContentsTitleStay">
                            <div className="lp_swiperTextContentsTitleStay--sub">
                              5F 日本料理 源氏香
                            </div>
                            <div className="lp_swiperTextContentsTitleStay--bold">
                              年越しそば
                            </div>
                          </div>
                          <div className="px-1">
                            <div className="lp_swiperTextContentsListText">
                              一年の締めくくりに、日本庭園を眺めながら年越しそばをご賞味ください。
                            </div>
                          </div>
                          <ul className="mt-30">
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2024年12月31日（火）<br />
                                21:30～23:30（L.O.23:00）
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　  2,600円
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/restaurants/plan/t4ziydqqx/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents3">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/restaurant/restaurant_dinner_03.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_swiperTextContentsTitleStay">
                            <div className="lp_swiperTextContentsTitleStay--sub">
                              5F 日本料理 源氏香
                            </div>
                            <div className="lp_swiperTextContentsTitleStay--bold">
                              新春ディナー会席
                            </div>
                          </div>
                          <div className="px-1">
                            <div className="lp_swiperTextContentsListText">
                              日本庭園を眺めながら新年を寿ぐ、3種類の会席をご用意いたしました。洗練された技法で仕上げた料理を、ゆったりとご堪能ください。
                            </div>
                          </div>
                          <ul className="mt-30">
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2025年1月1日（元日）～1月5日（日）<br />
                                1部 17:00～19:00／2部 19:30～21:30
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　18,000円～
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※ 1月4日、1月5日の営業時間は詳細よりご確認ください。
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/restaurants/plan/jtatxznwtv/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents3">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/restaurant/restaurant_dinner_04.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_swiperTextContentsTitleStay">
                            <div className="lp_swiperTextContentsTitleStay--sub">
                              B1F 中国料理 桂花苑
                            </div>
                            <div className="lp_swiperTextContentsTitleStay--bold">
                              新春ディナーコース
                            </div>
                          </div>
                          <div className="px-1">
                            <div className="lp_swiperTextContentsListText">
                              新春にふさわしい、華やかなディナーコースをご用意いたしました。フカヒレやエゾアワビ、トリュフ、真鯛など、豪華な食材を贅沢に使用したメニューを、優雅なひとときとともにご堪能ください。
                            </div>
                          </div>
                          <ul className="mt-30">
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2024年12月31日（火）～2025年1月5日（日）<br />
                                1部 17:00～19:00／2部 19:30～21:30
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　18,000円～
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※ 1月4日、1月5日の営業時間は詳細よりご確認ください。<br />
                                ※ 写真は23,000円のコースです。
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/restaurants/plan/es9ztnrjvn/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents3">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/restaurant/restaurant_dinner_05.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_swiperTextContentsTitleStay">
                            <div className="lp_swiperTextContentsTitleStay--sub">
                              1F シェフズダイニング シンフォニー
                            </div>
                            <div className="lp_swiperTextContentsTitleStay--bold">
                              新春ファミリーディナーブッフェ
                            </div>
                          </div>
                          <div className="px-1">
                            <div className="lp_swiperTextContentsListText">
                              年始の集いにおすすめのディナーブッフェ。彩り鮮やかで豊富なメニューと魅力あふれる多彩な味わいで皆様をおもてなしいたします。
                            </div>
                          </div>
                          <ul className="mt-30">
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                              2025年1月1日（元日）～1月5日（日）<br />
                              1部 17:00～19:00／2部 19:30～21:30
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　9,000円
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※ 1月4日、1月5日の営業時間は詳細よりご確認ください。<br />
                                ※ お子様、シニア料金あり
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/restaurants/plan/2pc2drrgvef/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
          <div className="lp_sect02Bg">
            <div ref={refPc6} className={`plum_3-1 u_pc ${inViewPc6 ? "animation" : ""}`}></div>
            <div ref={refSp8} className={`plum_3-1_sp u_sp ${inViewSp8 ? "animation" : ""}`}></div>
            {/* <div ref={refSp2} className={`plum_1-2_sp u_sp ${inViewSp2 ? "animation" : ""}`}></div> */}
            <div className="lp_sectTitleWrap">
              <img src="/assets/images/sp/newyear2025/icon/plan_icon.png" alt=""/>
              <div className="lp_sectTitle" id="plan">
                ご宿泊プラン
              </div>
            </div>
            <div className="mt-80 u_pc"></div>
            <div className="lp_tabContents">
              <div className="lp_tabContentsItem show">
                <div className="border u_pc"></div>
                <Swiper
                  onSwiper={setThumbsSwiper2}
                  loop={false}
                  spaceBetween={10}
                  slidesPerView={3}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper swiper2"
                  breakpoints={{
                    640: {
                      slidesPerView: 7,
                      spaceBetween: 10
                    }
                  }}
                >
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/stay/stay_slide_01.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/stay/stay_slide_02.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/stay/stay_slide_03.png" />
                  </SwiperSlide>
                </Swiper>
                <div className="border u_pc"></div>
                <Swiper
                  loop={false}
                  spaceBetween={0}
                  navigation={true}
                  thumbs={{ swiper: thumbsSwiper2 }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  <SwiperSlide>
                    <div className="lp_swiperContents1">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/stay/stay_slide_01.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_swiperTextContentsTitleStay">
                            <div className="lp_swiperTextContentsTitleStay--sub">
                              CHIC TOKYO STAY
                            </div>
                            <div className="lp_swiperTextContentsTitleStay--bold">
                              寿 “KOTOBUKI”
                            </div>
                          </div>
                          <div className="px-1">
                            <div className="lp_swiperTextContentsListText">
                              年末から正月三が日まで、年末年始を満喫できる贅を尽くしたプラン。お正月ならではのイベントや特別メニューをご家族や大切な方とともにご堪能いただけます。
                            </div>
                          </div>
                          <ul className="mt-30">
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2024年12月31日（火）<br />
                                ～2025年1月3日（金） 3泊4日プラン 
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金例</div>
                              <div className="lp_swiperTextContentsListText">
                                スタンダートフロア スタンダートツイン<br />
                                1室2名様ご利用時（夕・朝食付）<br />
                                ザ クラブ会員：お一人様　200,000円<br />
                                一般　　　　 ：お一人様　205,000円
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※朝食付プランもご用意しております。
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/stay/plan/s56xdhtwezn/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents1">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/stay/stay_slide_02.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_swiperTextContentsTitleStay">
                            <div className="lp_swiperTextContentsTitleStay--sub">
                              CHIC TOKYO STAY
                            </div>
                            <div className="lp_swiperTextContentsTitleStay--bold">
                              初春 “HATSUHARU”
                            </div>
                          </div>
                          <div className="px-1">
                            <div className="lp_swiperTextContentsListText">
                              年末年始を優雅にお過ごしいただけるプラン。年越しそばやおせち料理をはじめ、ホテル館内で行われる正月イベントなどもご満喫いただけます。
                            </div>
                          </div>
                          <ul className="mt-30">
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2024年12月31日（火）<br />
                                ～2025年1月2日（木） 2泊3日プラン
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金例</div>
                              <div className="lp_swiperTextContentsListText">
                                スタンダートフロア スタンダートツイン<br />
                                1室2名様ご利用時（夕・朝食付）<br />
                                ザ クラブ会員：お一人様　155,000円<br />
                                一般　　　　 ：お一人様　160,000円
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※朝食付プランもご用意しております。
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/stay/plan/s56xdhtwezn/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents1">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/stay/stay_slide_03.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_swiperTextContentsTitleStay">
                            <div className="lp_swiperTextContentsTitleStay--sub">
                              CHIC TOKYO STAY
                            </div>
                            <div className="lp_swiperTextContentsTitleStay--bold">
                              新春 “SHINSHUN”
                            </div>
                          </div>
                          <div className="px-1">
                            <div className="lp_swiperTextContentsListText">
                              正月三が日をゆったりとお寛ぎいただけるプラン。お正月ならではのイベントや特別メニューを楽しみながらご家族での穏やかなひとときをお過ごしください。
                            </div>
                          </div>
                          <ul className="mt-30">
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2025年1月1日（元日）<br />
                                ～1月3日（金） 2泊3日プラン
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金例</div>
                              <div className="lp_swiperTextContentsListText">
                                スタンダードフロア　スタンダードツイン<br />
                                1室2名様ご利用時（夕・朝食付）<br />
                                ザ クラブ会員：お一人様　128,000円<br />
                                一般　　　　 ：お一人様　133,000円
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※朝食付プランもご用意しております。
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/stay/plan/s56xdhtwezn/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </section>
        <section className="lp_sect02" id="event">
          <div className="lp_sect03Bg">
            <div ref={refPc7} className={`plum_4-1 u_pc ${inViewPc7 ? "animation" : ""}`}></div>
            <div ref={refPc3} className={`plum_2-1 u_pc ${inViewPc3 ? "animation" : ""}`}></div>
            <div ref={refSp9} className={`plum_4-1_sp u_sp ${inViewSp9 ? "animation" : ""}`}></div>

            <div className="lp_sectTitleWrap">
              <img src="/assets/images/sp/newyear2025/icon/event_icon.png" alt=""/>
              <div className="lp_sectTitle lp_sectTitle02">
                イベント
              </div>
            </div>
            <div className="mt-80 u_pc"></div>
            <div className="lp_tabContents">
              <div className="lp_tabContentsItem show">
                <div className="border u_pc"></div>
                <Swiper
                  onSwiper={setThumbsSwiper3}
                  loop={false}
                  spaceBetween={10}
                  slidesPerView={3}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper swiper3"
                  breakpoints={{
                    640: {
                      slidesPerView: 7,
                      spaceBetween: 10
                    }
                  }}
                >
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/event/event01.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/event/event02.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/event/event03.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/event/event04.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/event/event05.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/event/event06.png" />
                  </SwiperSlide>
                </Swiper>
                <div className="border u_pc"></div>
                <Swiper
                  loop={false}
                  spaceBetween={0}
                  navigation={true}
                  thumbs={{ swiper: thumbsSwiper3 }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  <SwiperSlide>
                    <div className="lp_swiperContents2">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/event/event01.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_swiperTextContentsTitleStay">
                            <div className="lp_swiperTextContentsTitleStay--sub">
                              1F ロビー／2F 宴会場
                            </div>
                            <div className="lp_swiperTextContentsTitleStay--bold">
                              縁日フェア
                            </div>
                          </div>
                          <div className="px-1">
                            <div className="lp_swiperTextContentsListText">
                              ホテル館内が、にぎやかで華やかな縁日会場に。新年の幸せを招く獅子舞や屋台コーナーでは縁日の人気メニューをホテルシェフがアレンジをしてご提供いたします。
                            </div>
                          </div>
                          <ul className="mt-30">
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2025年1月1日（元日）、1月2日（木）<br />
                                10:00～18:00
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                縁日チケット1枚　500円
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※ ご予約不要
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/information/r8ckvl4fut/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents2">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/event/event02.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_swiperTextContentsTitleStay">
                            <div className="lp_swiperTextContentsTitleStay--sub">
                              3F 宴会場 ロイヤルホール
                            </div>
                            <div className="lp_swiperTextContentsTitleStay--bold">
                              ホテルでおせち
                            </div>
                          </div>
                          <div className="px-1">
                            <div className="lp_swiperTextContentsListText">
                              新しい年の始まりに、日常を離れ心静かにおせち料理とお屠蘇を楽しみください。
                            </div>
                          </div>
                          <ul className="mt-30">
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2025年1月1日（元日）<br　/>
                                7:30～10:30（最終入店10:00）
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　8,000円
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/information/utnpuk159q/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents2">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/event/event03.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_swiperTextContentsTitleStay">
                            <div className="lp_swiperTextContentsTitleStay--sub">
                              3F 宴会場 ロイヤルホール
                            </div>
                            <div className="lp_swiperTextContentsTitleStay--bold">
                              新春 満福団らんブッフェ
                            </div>
                          </div>
                          <div className="px-1">
                            <div className="lp_swiperTextContentsListText">
                              和洋中折衷の料理を取り揃えたブッフェはいかがでしょうか。キッズスペースもご用意しておりますので、ゆっくりとご家族団らんのひとときをお過ごしください。
                            </div>
                          </div>
                          <ul className="mt-30">
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2025年1月1日（元日）、1月2日（木）<br />
                                11:30～15:00 <br />
                                17:00～21:00 <br />
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　11,000円
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※ お子様、シニア料金あり
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/information/nr9zux1l6/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents2">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/event/event04.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_swiperTextContentsTitleStay">
                            <div className="lp_swiperTextContentsTitleStay--sub">
                              20F 宴会場 パラッツオ
                            </div>
                            <div className="lp_swiperTextContentsTitleStay--bold">
                              ホテル最上階で楽しむ新春ランチブッフェ
                            </div>
                          </div>
                          <div className="px-1">
                            <div className="lp_swiperTextContentsListText">
                              最上階からの開放的な眺望とホテルならではの豊富なメニューをお楽しみいただけます。
                            </div>
                          </div>
                          <ul className="mt-30">
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2025年1月1日（元日）、1月2日（木）<br />
                                1部 11:00～13:00 <br />
                                2部 13:30～15:30 <br />
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　12,000円 
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※ お子様、シニア料金あり
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/information/k625hfc832z2/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents2">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/event/event05.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_swiperTextContentsTitleStay">
                            <div className="lp_swiperTextContentsTitleStay--sub">
                              1F ロビー
                            </div>
                            <div className="lp_swiperTextContentsTitleStay--bold">
                              もちつき
                            </div>
                          </div>
                          <div className="px-1">
                            <div className="lp_swiperTextContentsListText">
                              一年の幸福を祈願しながら、お囃子や太鼓とともに威勢よくお餅をつきあげるもちつきを開催。新年ならではのイベントを皆様でご覧ください。
                            </div>
                          </div>
                          <ul className="mt-30">
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2025年1月1日（元日）<br />
                                11:00～／14:00～ 各回約30分
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※ ご予約不要、鑑賞無料
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents2">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/event/event06.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_swiperTextContentsTitleStay">
                            <div className="lp_swiperTextContentsTitleStay--sub">
                              1F ロビー
                            </div>
                            <div className="lp_swiperTextContentsTitleStay--bold">
                              福袋
                            </div>
                          </div>
                          <div className="px-1">
                            <div className="lp_swiperTextContentsListText">
                              ホテルのご宿泊券やお食事券が入ったお得な福袋を販売いたします。
                            </div>
                          </div>
                          <ul className="mt-30">
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2025年1月1日（元日）<br />
                                10:00～18:00
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                30,000円～
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/information/rj4re6nm6p/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </section>
        <section className="lp_sect02" id="osechi">
          <div ref={refPc4} className={`plum_2-2 u_pc ${inViewPc4 ? "animation" : ""}`}></div>
          <div ref={refPc5} className={`plum_2-3 u_pc ${inViewPc5 ? "animation" : ""}`}></div>
          <div ref={refSp3} className={`plum_2-1_sp u_sp ${inViewSp3 ? "animation" : ""}`}></div>
          <div ref={refSp4} className={`plum_2-2_sp u_sp ${inViewSp4 ? "animation" : ""}`}></div>
          <div className="lp_sect02Bg">
            <div className="lp_sectTitleWrap">
              <img src="/assets/images/sp/newyear2025/icon/osechi_icon.png" alt=""/>
              <div className="lp_sectTitle lp_sectTitle02">
                おせち料理
              </div>
            </div>
            <div className="mt-80 u_pc"></div>
            <div className="lp_tabContents">
              <div className="lp_tabContentsItem show">
                <div className="border u_pc"></div>
                <Swiper
                  onSwiper={setThumbsSwiper4}
                  loop={false}
                  spaceBetween={10}
                  slidesPerView={3}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper swiper2"
                  breakpoints={{
                    640: {
                      slidesPerView: 7,
                      spaceBetween: 10
                    }
                  }}
                >
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/osechi/osechi_slide_01.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/osechi/osechi_slide_02.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2025/osechi/osechi_slide_03.png" />
                  </SwiperSlide>
                </Swiper>
                <div className="border u_pc"></div>
                <Swiper
                  loop={false}
                  spaceBetween={0}
                  navigation={true}
                  thumbs={{ swiper: thumbsSwiper4 }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  <SwiperSlide>
                    <div className="lp_swiperContentsWrap">
                      <div className="lp_swiperContents">
                        <div className="lp_swiperImg lp_swiperImgLeft">
                          <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/osechi/osechi_slide_01.png" alt=""></AssetImage>
                        </div>
                        <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                          <div className="">
                            <div className="lp_swiperTextContentsTitle">
                              <div className="lp_swiperTextContentsTitle--bold">
                                想咲おせち
                              </div>
                              <div className="lp_swiperTextContentsTitle--sub">
                                日本料理
                              </div>
                            </div>
                            <div className="px-1">
                              <div className="lp_swiperTextContentsList2Text">
                                日本料理長 岩田 好輝の想いが咲き誇る贅の限りを尽くした「想咲おせち」。縁起を担ぐ品々を丁寧に仕込み、美しく盛合せた三段重です。
                              </div>
                            </div>
                            <ul className="mt-30">
                              <li className="lp_swiperTextContentsList2">
                                <div className="lp_swiperTextContentsList2Text--bold">ご予約期間</div>
                                <div className="lp_swiperTextContentsList2Text">
                                  2024年12月18日（水）19:00まで
                                </div>
                              </li>
                              <li className="lp_swiperTextContentsList2">
                                <div className="lp_swiperTextContentsList2Text--bold">限定数</div>
                                <div className="lp_swiperTextContentsList2Text">10個</div>
                              </li>
                              <li className="lp_swiperTextContentsList2">
                                <div className="lp_swiperTextContentsList2Text--bold">料金</div>
                                <div className="lp_swiperTextContentsList2Text">108,000円</div>
                              </li>
                              <li className="lp_swiperTextContentsList2">
                                <div className="lp_swiperTextContentsList2Text--small">
                                  ※要予約
                                </div>
                              </li>
                            </ul>
                          </div>
                          <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b3347257c18e0835070b52&num_people=1&start_date=2024-12-31/" target="_blank">
                            <div className="lp_swiperTextContentsButtonosechi">
                              Web予約はこちら →
                            </div>
                          </a>
                          <div className="lp_swiperText--small">お電話、FAXでのご予約は<a href="https://www.rph.co.jp/private/os9yvr2b0/" target="_blank">こちら</a></div>
                        </div>
                      </div>
                      <div className="lp_swiperContentsMenuWrap">
                        <div className="lp_swiperContentsMenu u_pc">
                          <button className={openFlag ? "is_open" : ""} onClick={toggleClick}>お品書きはこちら
                            <i className={`icon-${openFlag ? "minus" : "plus"}`}></i>
                          </button>
                          <div className="lp_swiperContentsMenuList">
                            <div className={openFlag ? "is_open" : "is_close"}>
                              <div className="lp_swiperContentsMenuImg">
                                <img src="/assets/images/sp/newyear2025/menu/osechi_menu_01.png" alt=""/>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="lp_swiperContentsMenu u_sp">
                          <button className={openFlag ? "is_open" : ""} onClick={toggleClick}>お品書きはこちら
                            <i className={`icon-${openFlag ? "minus" : "plus"}`}></i>
                          </button>
                          <div className="lp_swiperContentsMenuList">
                            <div className={openFlag ? "is_open" : "is_close"}>
                              <div className="lp_swiperContentsMenuImg">
                                <img src="/assets/images/sp/newyear2025/menu/osechi_menu_01_sp.png" alt=""/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContentsWrap">
                      <div className="lp_swiperContents">
                        <div className="lp_swiperImg lp_swiperImgLeft">
                          <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/osechi/osechi_slide_02.png" alt=""></AssetImage>
                        </div>
                        <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                          <div className="">
                            <div className="lp_swiperTextContentsTitle">
                              <div className="lp_swiperTextContentsTitle--bold">
                                おせち料理 三段
                              </div>
                              <div className="lp_swiperTextContentsTitle--sub">
                                日本料理・フランス料理・中国料理
                              </div>
                            </div>
                            <div className="px-1">
                              <div className="lp_swiperTextContentsList2Text">
                                当ホテルならではの幅広い世代の方にお楽しみいただける和洋中折衷の三段重。多彩な美味とともに、新年の幕開けをお楽しみください。
                              </div>
                            </div>
                            <ul className="mt-30">
                              <li className="lp_swiperTextContentsList2">
                                <div className="lp_swiperTextContentsList2Text--bold">ご予約期間</div>
                                <div className="lp_swiperTextContentsList2Text">
                                  2024年12月18日（水）19:00まで
                                </div>
                              </li>
                              <li className="lp_swiperTextContentsList2">
                                <div className="lp_swiperTextContentsList2Text--bold">限定数</div>
                                <div className="lp_swiperTextContentsList2Text">100個</div>
                              </li>
                              <li className="lp_swiperTextContentsList2">
                                <div className="lp_swiperTextContentsList2Text--bold">料金</div>
                                <div className="lp_swiperTextContentsList2Text">59,400円</div>
                              </li>
                              <li className="lp_swiperTextContentsList2">
                                <div className="lp_swiperTextContentsList2Text--small">
                                  ※要予約
                                </div>
                              </li>
                            </ul>
                          </div>
                          <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b305d557c18e0835070132&num_people=1&start_date=2024-12-31" target="_blank">
                            <div className="lp_swiperTextContentsButtonosechi">
                              Web予約はこちら →
                            </div>
                          </a>
                          <div className="lp_swiperText--small">お電話、FAXでのご予約は<a href="https://www.rph.co.jp/private/os9yvr2b0/" target="_blank">こちら</a></div>
                        </div>
                      </div>
                      <div className="lp_swiperContentsMenuWrap">
                        <div className="lp_swiperContentsMenu u_pc">
                          <button className={openFlag ? "is_open" : ""} onClick={toggleClick}>お品書きはこちら
                            <i className={`icon-${openFlag ? "minus" : "plus"}`}></i>
                          </button>
                          <div className="lp_swiperContentsMenuList">
                            <div className={openFlag ? "is_open" : "is_close"}>
                              <div className="lp_swiperContentsMenuImg">
                                <img src="/assets/images/sp/newyear2025/menu/osechi_menu_02.png" alt=""/>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="lp_swiperContentsMenu u_sp">
                          <button className={openFlag ? "is_open" : ""} onClick={toggleClick}>お品書きはこちら
                            <i className={`icon-${openFlag ? "minus" : "plus"}`}></i>
                          </button>
                          <div className="lp_swiperContentsMenuList">
                            <div className={openFlag ? "is_open" : "is_close"}>
                              <div className="lp_swiperContentsMenuImg">
                                <img src="/assets/images/sp/newyear2025/menu/osechi_menu_02_sp.png" alt=""/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContentsWrap">
                      <div className="lp_swiperContents">
                        <div className="lp_swiperImg lp_swiperImgLeft">
                          <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2025/osechi/osechi_slide_03.png" alt=""></AssetImage>
                        </div>
                        <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                          <div className="">
                            <div className="lp_swiperTextContentsTitle">
                              <div className="lp_swiperTextContentsTitle--bold">
                                おせち料理 二段
                              </div>
                              <div className="lp_swiperTextContentsTitle--sub">
                                日本料理
                              </div>
                            </div>
                            <div className="px-1">
                              <div className="lp_swiperTextContentsList2Text">
                                おせち料理の定番の品々を種類豊富に詰合せ、手間を惜しまず匠の技で味わい深く仕上げました。少人数のご家庭にも人気です。
                              </div>
                            </div>
                            <ul className="mt-30">
                              <li className="lp_swiperTextContentsList2">
                                <div className="lp_swiperTextContentsList2Text--bold">ご予約期間</div>
                                <div className="lp_swiperTextContentsList2Text">
                                  2024年12月18日（水）19:00まで
                                </div>
                              </li>
                              <li className="lp_swiperTextContentsList2">
                                <div className="lp_swiperTextContentsList2Text--bold">限定数</div>
                                <div className="lp_swiperTextContentsList2Text">100個</div>
                              </li>
                              <li className="lp_swiperTextContentsList2">
                                <div className="lp_swiperTextContentsList2Text--bold">料金</div>
                                <div className="lp_swiperTextContentsList2Text">41,040円</div>
                              </li>
                              <li className="lp_swiperTextContentsList2">
                                <div className="lp_swiperTextContentsList2Text--small">
                                  ※要予約
                                </div>
                              </li>
                            </ul>
                          </div>
                          <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b31746af3d63bd6fbb9ac4&num_people=1&start_date=2024-12-31" target="_blank">
                            <div className="lp_swiperTextContentsButtonosechi">
                              Web予約はこちら →
                            </div>
                          </a>
                          <div className="lp_swiperText--small">お電話、FAXでのご予約は<a href="https://www.rph.co.jp/private/os9yvr2b0/" target="_blank">こちら</a></div>
                        </div>
                      </div>
                      <div className="lp_swiperContentsMenuWrap">
                        <div className="lp_swiperContentsMenu u_pc">
                          <button className={openFlag ? "is_open" : ""} onClick={toggleClick}>お品書きはこちら
                            <i className={`icon-${openFlag ? "minus" : "plus"}`}></i>
                          </button>
                          <div className="lp_swiperContentsMenuList">
                            <div className={openFlag ? "is_open" : "is_close"}>
                              <div className="lp_swiperContentsMenuImg">
                                <img src="/assets/images/sp/newyear2025/menu/osechi_menu_03.png" alt=""/>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="lp_swiperContentsMenu u_sp">
                          <button className={openFlag ? "is_open" : ""} onClick={toggleClick}>お品書きはこちら
                            <i className={`icon-${openFlag ? "minus" : "plus"}`}></i>
                          </button>
                          <div className="lp_swiperContentsMenuList">
                            <div className={openFlag ? "is_open" : "is_close"}>
                              <div className="lp_swiperContentsMenuImg">
                                <img src="/assets/images/sp/newyear2025/menu/osechi_menu_03_sp.png" alt=""/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </section>
        <section className="lp_sect05" id="">
          <div ref={refPc8} className={`plum_5-1 u_pc ${inViewPc8 ? "animation" : ""}`}></div>
          <div ref={refSp5} className={`plum_5-1_sp u_sp ${inViewSp5 ? "animation" : ""}`}></div>
          <div ref={refSp6} className={`plum_5-2_sp u_sp ${inViewSp6 ? "animation" : ""}`}></div>
          <div className="lp_attentionWrap">
            <ul className="lp_attentionText">
              <li>※レストランプラン、イベントの料金には消費税・サービス料が含まれます。</li>
              <li>※ご宿泊プランの料金には、消費税・サービス料・夕朝食・東京都宿泊税が含まれます。</li>
              <li>※おせち料理の料金には消費税（8％）が含まれます。</li>
              <li>※⾷材の⼊荷状況により、内容を変更させていただく場合がございます。</li>
              <li>※限定商品のため、販売終了の際はご容赦ください。</li>
              <li>※掲載の写真は実際と異なる場合がございます。</li>
            </ul>
          </div>
        </section>
        <section className="lp_snsBlock">
          <a href="https://www.instagram.com/royalparkhotel.tokyo/" target="_blank">
            <div className="lp_snsBlockSubTitle">\ Follow me /</div>
            <div className="lp_snsBlockTitle">Instagram</div>
            <img src="/assets/images/sp/newyear2025/icon/Instagram_icon.png" alt="Instagram_icon"/>
            <div className="lp_snsBlockAddress u_pc">@royalparkhotel.tokyo</div>
            <div className="lp_snsBlockText">
              ロイヤルパークホテルのご案内や、イベント、最新情報をご紹介！<br className="u_pc" />
              皆様からのたくさんのフォローをお待ちしております。
            </div>
            <div className="lp_snsBlockAddress u_sp">@royalparkhotel.tokyo</div>
          </a>
        </section>
        
        <div className="lp_newYearFooter disnon u_sp">
          <ul>
            <li>
              <a href="#restaurant">
                <div className="lp_newYearFooterImg">
                  <AssetImage
                    src="/assets/images/sp/newyear2024/icon/restaurant_icon_w.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="lp_newYearFooterText">レストラン</div>
              </a>
            </li>
            <li>
              <a href="#stay">
                <div className="lp_newYearFooterImg">
                  <AssetImage
                    src="/assets/images/sp/newyear2024/icon/stay_icon_w.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
              <div className="lp_newYearFooterText">ステイ</div>
              </a>
            </li>
            <li>
              <a href="#event">
                <div className="lp_newYearFooterImg">
                  <AssetImage
                    src="/assets/images/sp/newyear2024/icon/event_icon_w.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="lp_newYearFooterText">イベント</div>
              </a>
            </li>
            <li>
              <a href="#osechi">
                <div className="lp_newYearFooterImg">
                  <AssetImage
                    src="/assets/images/sp/newyear2024/icon/osechi_icon_w.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="lp_newYearFooterText">おせち料理</div>
              </a>
            </li>
          </ul>
        </div>
        {/* modal */}
        {modalFlagEvent &&  (
          <CLpModalNewyear onClose={openModalEvent} exClass="p_access_modal">
            <div className="c_lpModalNewyear">
              <div className="c_lpModalNewyearTitleText u_sp">
                1F ロビー<br />
                <div className="c_lpModalNewyearTitle">
                  もちつき
                </div>
              </div>
              <div className="c_lpModalNewyearImg">
                <img src="/assets/images/sp/newyear2024/event5.png" alt=""/>
              </div>
              <div className="c_lpModalNewyearTextWrap">
                <div>
                  <div className="c_lpModalNewyearTitle u_pc">
                    1F ロビー<br />
                    <div className="c_lpModalNewyearTitleBold">
                      もちつき
                    </div>
                  </div>
                  <div className="c_lpModalNewyearText">
                    お正月飾りに彩られたロビーで、一年の幸福を祈願しながら、お囃子や太鼓とともに威勢よくお餅をつきあげます。
                  </div>
                  <ul>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">期間</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        2024年1月1日（元日）<br />
                        11:00～／14:00～ 各回約30分
                      </div>
                    </li>
                  </ul>
                  <div className="c_lpModalNewyearAttention">
                    ※ ご予約不要、鑑賞無料
                  </div>
                </div>
              </div>
            </div>
          </CLpModalNewyear>
        )}
        {modalFlag && (
          <CLpModalNewyear onClose={openModal} exClass="p_access_modal">
            <div className="c_lpModalNewyear">
              <div className="c_lpModalNewyearTitle u_sp">
                想咲おせち<br />
                <div className="c_lpModalNewyearTitleBold">
                  日本料理
                </div>
              </div>
              <div className="c_lpModalNewyearImg">
                <img src="/assets/images/sp/newyear2024/osechi1.png" alt=""/>
              </div>
              <div className="c_lpModalNewyearTextWrap">
                <div>
                  <div className="c_lpModalNewyearTitle u_pc">
                    想咲おせち<br />
                    <div className="c_lpModalNewyearTitleBold">
                      日本料理
                    </div>
                  </div>
                  <div className="c_lpModalNewyearText">
                    日本料理長　岩田 好輝が一品一品素材を選りすぐり、新年の平安を願って作り上げる「想咲おせち」。縁起を担ぐ品々を丁寧に仕込み、美しく盛合せた三段重です。
                  </div>
                  <ul>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">ご予約期間</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        2023年12月20日（水）19:00まで
                      </div>
                    </li>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">料金</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        108,000円
                      </div>
                    </li>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">限定数</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        15個／要予約
                      </div>
                    </li>
                  </ul>
                  <div className="c_lpModalNewyearAttention">
                    ※ 限定商品のため、販売終了の際はご容赦ください。
                  </div>
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=64cb10793a872300b52655e8&num_people=1&start_date=2023-12-31" target="_blank">
                  <div className="c_lpModalNewyearButton">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </CLpModalNewyear>
        )}
        {modalFlag02 && (
          <CLpModalNewyear onClose={openModal02} exClass="p_access_modal">
            <div className="c_lpModalNewyear">
              <div className="c_lpModalNewyearTitle u_sp">
                おせち料理 三段<br />
                <div className="c_lpModalNewyearTitleBold">
                  日本料理・フランス料理・中国料理
                </div>
              </div>
              <div className="c_lpModalNewyearImg">
                <img src="/assets/images/sp/newyear2024/osechi2.png" alt=""/>
              </div>
              <div className="c_lpModalNewyearTextWrap">
                <div>
                  <div className="c_lpModalNewyearTitle u_pc">
                    おせち料理 三段<br />
                    <div className="c_lpModalNewyearTitleBold">
                      日本料理・フランス料理・中国料理
                    </div>
                  </div>
                  <div className="c_lpModalNewyearText">
                    日本料理・フランス料理・中国料理を一度に味わえる、幅広い世代の方に好まれる豪華な三段重。多彩な美味とともに、新年の幕開けをお楽しみください。
                  </div>
                  <ul>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">ご予約期間</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        2023年12月20日（水）19:00まで
                      </div>
                    </li>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">料金</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        59,400円
                      </div>
                    </li>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">限定数</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        100個／要予約
                      </div>
                    </li>
                  </ul>
                  <div className="c_lpModalNewyearAttention">
                    ※ 限定商品のため、販売終了の際はご容赦ください。
                  </div>
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=64cb10793a872300b52655e8&num_people=1&start_date=2023-12-31" target="_blank">
                  <div className="c_lpModalNewyearButton">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </CLpModalNewyear>
        )}
        {modalFlag03 && (
          <CLpModalNewyear onClose={openModal03} exClass="p_access_modal">
            <div className="c_lpModalNewyear">
              <div className="c_lpModalNewyearTitle u_sp">
                おせち料理 二段<br />
                <div className="c_lpModalNewyearTitleBold">
                  日本料理
                </div>
              </div>
              <div className="c_lpModalNewyearImg">
                <img src="/assets/images/sp/newyear2024/osechi3.png" alt=""/>
              </div>
              <div className="c_lpModalNewyearTextWrap">
                <div>
                  <div className="c_lpModalNewyearTitle u_pc">
                    おせち料理 二段<br />
                    <div className="c_lpModalNewyearTitleBold">
                      日本料理
                    </div>
                  </div>
                  <div className="c_lpModalNewyearText">
                    日本料理長こだわりの品々を種類豊富に詰合せ、手間を惜しまず匠の技で味わい深く仕上げました。少人数のご家庭にも人気のおせち料理です。
                  </div>
                  <ul>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">ご予約期間</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        2023年12月20日（水）19:00まで
                      </div>
                    </li>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">料金</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        41,040円
                      </div>
                    </li>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">限定数</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        100個／要予約
                      </div>
                    </li>
                  </ul>
                  <div className="c_lpModalNewyearAttention">
                    ※ 限定商品のため、販売終了の際はご容赦ください。
                  </div>
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=64cb10793a872300b52655e8&num_people=1&start_date=2023-12-31" target="_blank">
                  <div className="c_lpModalNewyearButton">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </CLpModalNewyear>
        )}
      </div>
    </LayoutLp>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
